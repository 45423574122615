import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  Pagination as GrommetPagination,
  PaginationProps,
  Text,
} from 'grommet';
import { Card } from '@driven-education/driven-styles';

export const DEFAULT_PAGE_STEP = 50;

export function Pagination(props: Omit<PaginationProps, 'step'>) {
  const { page, numberItems } = props;

  const message = useMemo(() => {
    const totalMessage = `${numberItems} registros`;
    const pageMessage = page
      ? `${(page - 1) * DEFAULT_PAGE_STEP + 1}-${page * DEFAULT_PAGE_STEP}`
      : '';

    return `${pageMessage} de ${totalMessage}`;
  }, [page, numberItems]);

  return (
    <Container level={3}>
      <BasePagination {...props} />
      <Text>{message}</Text>
    </Container>
  );
}

export const BasePagination = styled(GrommetPagination).attrs({
  step: DEFAULT_PAGE_STEP,
})``;

const Container = styled(Card).attrs({
  direction: 'row',
  align: 'center',
  gap: 'small',
})`
  padding: ${({ theme }) => theme.global?.edgeSize?.medium} 0;
  color: white;
`;
