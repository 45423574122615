import React from 'react';
import styled from 'styled-components';
import { Heading, Card } from '@driven-education/driven-styles';

export function ResourceHeader(props: Props) {
  const { name, children } = props;

  return (
    <HeaderContainer level={3}>
      <Heading level={2}>{name.toUpperCase()}</Heading>
      {children}
    </HeaderContainer>
  );
}

type Props = React.PropsWithChildren<{
  name: string;
}>;

const HeaderContainer = styled(Card).attrs({
  flex: true,
  align: 'start',
  justify: 'between',
  direction: 'row',
})`
  flex: inherit;
  color: white;
`;
