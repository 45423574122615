import React, { ReactElement, useMemo } from 'react';
import { Sidebar as GrommetSidebar, Nav, BoxExtendedProps } from 'grommet';
import { useLocation } from 'react-router-dom';
import { Card } from '@driven-education/driven-styles';

import {
  ActivatableNavLink,
  ActivatableNavLinkProps,
} from './ActivatableNavLink';
import StyledCard from './StyledCard';

export function Sidebar(props: BoxExtendedProps) {
  const { pathname } = useLocation();
  const menu = useMemo(() => {
    return pathname.split('/')[1];
  }, [pathname]);

  function loadMenu(): ReactElement {
    switch (menu) {
      case 'processo-seletivo':
        return <SelectiveProcessMenu />;
      case 'minicurso':
        return <CourseMenu />;
      case 'desafio':
        return <AssessmentMenu />;
      default:
        return <SelectiveProcessMenu />;
    }
  }

  return (
    <StyledCard {...props} direction="row" height={{ min: '100%' }}>
      <GrommetSidebar
        responsive={false}
        background="dark-1"
        pad={{ left: 'small', right: 'small', vertical: 'small' }}
        width={{ min: '200px' }}
      >
        <Nav gap="small" responsive={false}>
          {loadMenu()}
        </Nav>
      </GrommetSidebar>
    </StyledCard>
  );
}

function SelectiveProcessMenu() {
  return (
    <>
      <SidebarNavLink label="Turmas" to="/processo-seletivo/turmas" />
      <SidebarNavLink label="Candidatos" to="/processo-seletivo/candidatos" />
      <SidebarNavLink
        label="Resultado dos Desafios"
        to="/processo-seletivo/resultado-desafios"
      />
    </>
  );
}

function CourseMenu() {
  return (
    <>
      <SidebarNavLink label="Seções" to="/minicurso/secoes" />
      <SidebarNavLink label="Vídeos do minicurso" to="/minicurso/videos" />
      <SidebarNavLink
        label="Exercícios do minicurso"
        to="/minicurso/exercicios"
      />
    </>
  );
}

function AssessmentMenu() {
  return (
    <>
      <SidebarNavLink label="Desafios" to="/desafio/desafios" />
      <SidebarNavLink label="Questões" to="/desafio/questoes" />
    </>
  );
}

function SidebarNavLink(props: ActivatableNavLinkProps) {
  return (
    <Card>
      <ActivatableNavLink {...props} />
    </Card>
  );
}
