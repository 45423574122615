import { useToast } from '@driven-education/driven-styles';
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  ReactElement,
  useContext,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

interface IProvider {
  onUserNotFound: () => void;
  onUnknownError: () => void;
  onExpiredToken: () => void;
  onServerUnavailable: () => void;
  onLogout: () => void;
}

export const ErrorHandlerContext = createContext<IProvider>({} as IProvider);

export function ErrorHandlerProvider({
  children,
}: PropsWithChildren<ReactNode>): ReactElement {
  const navigate = useNavigate();
  const { toast } = useToast();

  const result: IProvider = useMemo(
    () => ({
      onUserNotFound,
      onUnknownError,
      onExpiredToken,
      onServerUnavailable,
      onLogout,
    }),
    []
  );

  function onUserNotFound() {
    toast({
      title: 'Não encontramos esse usuário 🙁',
      description: 'Por favor verifique o email cadastrado!',
      status: 'error',
    });
  }

  function onUnknownError() {
    toast({
      status: 'error',
      title: 'Ops! Ocorreu um erro desconhecido 😢',
    });
  }

  function onExpiredToken() {
    toast({
      title: 'Não autorizado 🙁',
      description: 'Seu token expirou, solicite uma nova redefinição.',
      status: 'error',
    });
  }

  function onServerUnavailable() {
    navigate('/unavailable-service');
  }

  function onLogout() {
    toast({
      title: 'Sessão expirada 🙁',
      description: 'Por favor, faça seu login novamente.',
      status: 'warning',
    });
  }

  return (
    <ErrorHandlerContext.Provider value={result}>
      {children}
    </ErrorHandlerContext.Provider>
  );
}

export const useErrorHandler = (): IProvider => useContext(ErrorHandlerContext);
