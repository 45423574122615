import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorDisplay, Label } from '@/components';
import { Select } from '../../selectors';

export function SelectInput(props: Props) {
  const {
    disabled = false,
    label,
    fieldName,
    required = false,
    options,
  } = props;
  const {
    watch,
    register,
    formState: { errors },
    setValue,
  } = useFormContext();

  return (
    <>
      <Label>{label}</Label>
      <Select
        options={options}
        disabled={disabled}
        {...register(fieldName, {
          required,
          onChange: (e) => {
            setValue(fieldName, e.value.value);
          },
        })}
        value={options.find((o) => o.value === watch(fieldName))}
        labelKey="label"
        valueKey="value"
        placeholder={label}
      />

      {errors[fieldName] && (
        <ErrorDisplay>{`O campo ${label} é obrigatório.`}</ErrorDisplay>
      )}
    </>
  );
}

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
  options: Option[];
};

type Option = {
  label: unknown;
  value: string;
};
