import { ApiClient } from '@/api';

export async function useUploadImage(
  file: File,
  api: ApiClient
): Promise<string> {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const res = await api.post<{ url: string }>('/files/upload', {
      body: formData,
    });

    return res.body.url;
  } catch {
    // eslint-disable-next-line no-console
    console.error('Error uploading image');
    return '';
  }
}
