import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useSearchParamsState<T>(
  identifier: string,
  initialValue: T
): Result<T> {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [state, setState] = useState<T>(initialValue);

  useEffect(() => {
    const searchParam = searchParams.get(identifier);
    if (searchParam) {
      setState(JSON.parse(searchParam));
    }
  }, []);

  useEffect(() => {
    const value = searchParams.get(identifier);
    if (value) {
      setState(JSON.parse(value));
    }
  }, [identifier]);

  function setSearchParamsState(v: T) {
    searchParams.set(identifier, JSON.stringify(v));
    navigate(`${location.pathname}?${searchParams.toString()}`);

    setState(v);
  }

  return [state, setSearchParamsState];
}

type Result<T> = [T, (incomingState: T) => void];
