import { UseTableHooks } from 'react-table';

export function CPFDisplay({ cell }: UseTableHooks<Record<string, unknown>>) {
  const maskCPF = (cpf: string) => {
    return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(
      6,
      9
    )}-${cpf.substring(9, 11)}`;
  };

  return cell.value ? maskCPF(cell.value) : 'Não disponível';
}
