import React, { ReactElement } from 'react';
import { Modal, Card, Heading } from '@driven-education/driven-styles';
import styled from 'styled-components';

import { useQuery } from 'react-query';
import { LoadingComponent } from '@/components';
import { TestsResult } from '@/protocols';
import { TestsMessage } from './TestsMessage';

export function ExerciseTestsModal({
  handleRunTests,
  onClose,
}: Props): ReactElement {
  const { data: testsResult, isLoading } = useQuery(['runTests'], async () =>
    handleRunTests()
  );

  return (
    <StyledModal onClose={onClose}>
      <Card>
        {testsResult ? (
          <TestsMessage testsResult={testsResult} />
        ) : isLoading ? (
          <LoadingComponent />
        ) : (
          <Heading level={3}>Nenhum teste encontrado</Heading>
        )}
      </Card>
    </StyledModal>
  );
}

type Props = {
  onClose: () => void;
  handleRunTests: () => Promise<TestsResult | undefined>;
};

const StyledModal = styled(Modal)`
  width: 50%;
`;
