import React from 'react';
import { Reset } from 'styled-reset';
import { MainProvider } from '@/providers';
import GlobalStyle from '@/styles/GlobalStyle';
import { Router } from '@/routes';

function App() {
  return (
    <MainProvider>
      <Reset />
      <GlobalStyle />
      <Router />
    </MainProvider>
  );
}

export default App;
