import React from 'react';
import { UseTableHooks } from 'react-table';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';

export function PublishIcon(props: UseTableHooks<Record<string, unknown>>) {
  const { cell } = props;

  return cell.value ? (
    <AiOutlineCheck size={20} />
  ) : (
    <AiOutlineClose size={20} />
  );
}
