import React, { PropsWithChildren, ReactNode, ReactElement } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { useCurrentUser, useErrorHandler } from '@/contexts';
import {
  AuthenticationError,
  UnavailableServiceError,
  UnknownServiceError,
} from '../errors';

export function QueryProvider(
  props: PropsWithChildren<ReactNode>
): ReactElement {
  const { children } = props;
  const { signOut } = useCurrentUser();
  const { onUnknownError, onExpiredToken, onServerUnavailable } =
    useErrorHandler();

  function errorHandler(err: unknown): void {
    if (err instanceof UnavailableServiceError) {
      onServerUnavailable();
    } else if (err instanceof AuthenticationError) {
      signOut();
      onExpiredToken();
    } else if (err instanceof UnknownServiceError) {
      onUnknownError();
    } else {
      onUnknownError();
    }
  }

  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              refetchOnWindowFocus: false,
              onError: errorHandler,
            },
          },
        })
      }
    >
      {children}
    </QueryClientProvider>
  );
}
