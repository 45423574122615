import { Button } from '@driven-education/driven-styles';
import React from 'react';
import { UseTableHooks } from 'react-table';
import styled from 'styled-components';

export function CellButton<T>(props: CellButtonProps<T>) {
  const { onClick, values, icon } = props;
  return (
    <CustomNavigationButton
      {...values}
      onClick={() => {
        const {
          cell: {
            row: { id },
          },
          data,
        } = values;

        onClick(data[id]);
      }}
      icon={icon}
    />
  );
}

export type CellButtonProps<T> = {
  values: UseTableHooks<Record<string, unknown>>;
  onClick: (value: T) => void;
  icon?: React.ReactElement;
};

const CustomNavigationButton = styled(Button)`
  background-color: transparent;
  transition: all 0.3s ease-out;
  &:hover {
    background-color: ${({ theme }) => theme.global?.colors?.brand};
  }
  border-radius: 5px;
  outline: none;
  display: flex;
  align-items: center;
`;
