import React, { useEffect } from 'react';
import { useTrailResource } from '@/hooks';
import { Batch } from '@/protocols';
import { ResourceSelector } from './ResourceSelector';

export function BatchesSelector(props: Props) {
  const { onBatchSelect, shouldClear = false, onClear } = props;

  const {
    isLoading: areBatchesLoading,
    list: batches,
    current: selectedBatch,
    setCurrent: setCurrentBatch,
  } = useTrailResource<Batch>({
    identifier: 'batchId',
    queryParams: {
      name: 'getManyBatches',
      path: '/batches',
    },
  });

  useEffect(() => {
    onBatchSelect(selectedBatch);
  }, [selectedBatch]);

  useEffect(() => {
    if (!shouldClear) return;
    setCurrentBatch(undefined);
    onClear?.();
  }, [shouldClear]);

  return (
    <ResourceSelector<Batch>
      name="turma"
      resources={batches}
      labelAccessor="name"
      selectedResource={selectedBatch}
      onChange={setCurrentBatch}
      disabled={areBatchesLoading}
    />
  );
}

type Props = {
  onBatchSelect: (batch?: Batch) => void;
  shouldClear?: boolean;
  onClear?: () => void;
};
