import React from 'react';
import { TableHeader, TableRow, TableCell, Heading, TableBody } from 'grommet';
import { useTable, Column } from 'react-table';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { HiSwitchVertical } from 'react-icons/hi';
import { DragButton, StyledTable } from './components';

export function BaseTable(props: BaseTableProps) {
  const { columns, data, isDraggable, onDragEnd } = props;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  if (rows.length === 0)
    return (
      <Heading level="4" margin="20px 15px">
        Nenhum resultado encontrado
      </Heading>
    );

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd?.(result)}>
      <StyledTable {...getTableProps()}>
        <TableHeader>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {isDraggable && (
                <TableCell>
                  <HiSwitchVertical size={24} />
                </TableCell>
              )}
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <Droppable droppableId="list">
          {(provided) => (
            <TableBody
              {...getTableBodyProps()}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Draggable
                    draggableId={row.id}
                    index={row.index}
                    isDragDisabled={!isDraggable}
                  >
                    {(rowProvided) => (
                      <TableRow
                        {...row.getRowProps()}
                        {...rowProvided.draggableProps}
                        ref={rowProvided.innerRef}
                      >
                        {isDraggable && (
                          <DragButton {...rowProvided.dragHandleProps} />
                        )}
                        {row.cells.map((cell, index) => {
                          return (
                            // eslint-disable-next-line react/no-array-index-key
                            <TableCell {...cell.getCellProps()} key={index}>
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </StyledTable>
    </DragDropContext>
  );
}

export type BaseTableProps<R = object> = {
  columns: Column[];
  data: R[];
  isDraggable: boolean;
  onDragEnd?: (result: DropResult) => void;
};
