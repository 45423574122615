import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { ErrorDisplay } from '@/components/errors';
import { Label } from '../Label';
import { Editor } from './Editor';

export function CodeInput(props: Props): ReactElement {
  const { label, fieldName, disabled = false, required = false } = props;
  const {
    register,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext();

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Editor
        {...register(fieldName, { required })}
        value={watch(fieldName)}
        readOnly={isSubmitting || disabled}
        onChange={(value) => setValue(fieldName, value)}
      />

      {errors[fieldName] && (
        <ErrorDisplay>{`O campo ${label} é obrigatório.`}</ErrorDisplay>
      )}
    </Container>
  );
}

type Props = {
  label?: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin: 25px 0 0 0;
`;
