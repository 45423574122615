import React, { ReactElement, useRef } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { HiPlus, HiTrash } from 'react-icons/hi';
import styled from 'styled-components';
import { Button, Card } from '@driven-education/driven-styles';

import { ErrorDisplay, Label } from '@/components';
import { Editor } from './Editor';

export function CodeInputList(props: Props): ReactElement {
  const { label, fieldName, disabled = false, required = false } = props;
  const ref = useRef<HTMLDivElement>(null);
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
    keyName: 'key',
  });

  return (
    <Container>
      {label && <Label>{label}</Label>}
      {fields.map((field, index) => (
        <Card key={field.key} direction="row" gap="25px" width="100%" ref={ref}>
          <Editor
            {...register(`${fieldName}.${index}.body`, { required })}
            value={watch(`${fieldName}.${index}.body`)}
            readOnly={isSubmitting || disabled}
            onChange={(value) => setValue(`${fieldName}.${index}.body`, value)}
          />
          <div>
            <Button
              type="button"
              onClick={() => remove(index)}
              icon={<HiTrash />}
              size="small"
            />
          </div>
        </Card>
      ))}

      <Card align="center">
        <Button
          type="button"
          onClick={() => append({ body: '' })}
          icon={<HiPlus />}
          label="Adicionar"
          size="small"
        />
      </Card>

      {errors[fieldName] && (
        <ErrorDisplay>{`O campo ${label} deve ter pelo menos um item.`}</ErrorDisplay>
      )}
    </Container>
  );
}

type Props = {
  label?: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
};

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;
