import React from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ext-language_tools';

export function Editor(props: Props) {
  const { onChange, value, readOnly = false, height = '350px' } = props;

  return (
    <AceEditor
      readOnly={readOnly}
      onChange={onChange}
      value={value}
      theme="monokai"
      width="100%"
      height={height}
      placeholder=""
      mode="javascript"
      fontSize={18}
      showPrintMargin
      showGutter
      highlightActiveLine
      setOptions={{
        behavioursEnabled: true,
        enableBasicAutocompletion: true,
        enableLiveAutocompletion: true,
        enableSnippets: true,
        showLineNumbers: true,
        tabSize: 2,
        wrap: true,
        showPrintMargin: false,
      }}
    />
  );
}

type Props = {
  onChange?: (value?: string) => void;
  value: string;
  readOnly?: boolean;
  height?: string;
};
