import {
  AuthenticationError,
  ConflictError,
  NotFoundError,
  UnknownServiceError,
  UnavailableServiceError,
  BadRequestError,
  TooManyRequests,
  UnprocessableEntityError,
} from '@/errors';

export class ApiClient {
  private token = '';

  authenticate(token: string): void {
    this.token = token;
  }

  get<T>(
    path: string,
    requestOptions: RequestParams = {}
  ): Promise<ResponseType<T>> {
    return this.request<T>('GET', path, requestOptions);
  }

  post<T>(
    path: string,
    requestOptions: RequestParams = {}
  ): Promise<ResponseType<T>> {
    return this.request<T>('POST', path, requestOptions);
  }

  put<T>(
    path: string,
    requestOptions: RequestParams = {}
  ): Promise<ResponseType<T>> {
    return this.request<T>('PUT', path, requestOptions);
  }

  delete<T>(
    path: string,
    requestOptions: RequestParams = {}
  ): Promise<ResponseType<T>> {
    return this.request<T>('DELETE', path, requestOptions);
  }

  private async request<T>(
    method: 'POST' | 'GET' | 'PUT' | 'DELETE',
    path: string,
    { body, query }: RequestParams = {}
  ): Promise<ResponseType<T>> {
    let response: Response;
    try {
      response = await fetch(this.generateUrl(path, query), {
        method,
        ...(body && {
          body: body instanceof FormData ? body : JSON.stringify(body),
        }),
        mode: 'cors',
        headers: {
          ...(!(body instanceof FormData) && {
            'Content-Type': 'application/json',
          }),
          ...(!!this.token && { Authorization: `Bearer ${this.token}` }),
        },
      });
    } catch (e) {
      throw new UnavailableServiceError();
    }

    if (response.status === 401) {
      throw new AuthenticationError();
    } else if (response.status === 404) {
      throw new NotFoundError();
    } else if (response.status === 409) {
      throw new ConflictError();
    } else if (response.status === 400) {
      const res = await response.json();
      throw new BadRequestError(res.error);
    } else if (response.status === 422) {
      const res = await response.json();
      throw new UnprocessableEntityError(res.error);
    } else if (response.status === 429) {
      throw new TooManyRequests();
    } else if (response.status >= 400) {
      throw new UnknownServiceError();
    }

    const data = response.status !== 204 ? await response.json() : null;
    return {
      status: response.status,
      body: data,
      headers: response.headers,
    };
  }

  private generateUrl(
    path: string,
    query: RequestParams['query'] = {}
  ): string {
    const parsedParams = Object.entries(query).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: JSON.stringify(value),
      }),
      {}
    );

    const tail = path.startsWith('/') ? path : `/${path}`;
    const url = new URL(`${process.env.REACT_APP_API}${tail}`);
    url.search = new URLSearchParams(Object.entries(parsedParams)).toString();

    return url.toString();
  }
}

export type RequestParams = {
  body?: Record<string, unknown> | Record<string, unknown>[] | FormData;
  query?: Record<string, unknown>;
};

export type ResponseType<T> = {
  status: number;
  body: T;
  headers: Headers;
};
