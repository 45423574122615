import React, { Suspense, lazy } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';

import { LoadingPage } from '@/components';

const SignIn = lazy(() => import('@/pages/SignIn/SignIn'));
const Batches = lazy(() => import('@/pages/SelectionProcess/Batches/Batches'));
const Candidates = lazy(
  () =>
    import('@/pages/SelectionProcess/Candidates/ListCandidates/ListCandidates')
);
const AssessmentsResult = lazy(
  () => import('@/pages/SelectionProcess/AssessmentsResult/AssessmentResult')
);
const AssessmentReset = lazy(
  () => import('@/pages/SelectionProcess/AssessmentReset/AssessmentReset')
);
const EditCandidate = lazy(
  () =>
    import('@/pages/SelectionProcess/Candidates/EditCandidate/EditCandidate')
);
const Sections = lazy(
  () => import('@/pages/Course/Sections/ListSections/ListSections')
);
const CreateSection = lazy(
  () => import('@/pages/Course/Sections/CreateSection/CreateSection')
);
const EditSection = lazy(
  () => import('@/pages/Course/Sections/EditSection/EditSection')
);
const Lectures = lazy(() => import('@/pages/Course/Lectures/Lectures'));
const Exercises = lazy(
  () => import('@/pages/Course/Exercises/ListExercises/ListExercises')
);
const CreateExercise = lazy(
  () => import('@/pages/Course/Exercises/CreateExercise/CreateExercise')
);
const EditExercise = lazy(
  () => import('@/pages/Course/Exercises/EditExercise/EditExercise')
);
const ShowExercise = lazy(
  () => import('@/pages/Course/Exercises/ShowExercise/ShowExercise')
);
const Questions = lazy(
  () => import('@/pages/Assessments/Questions/ListQuestions/ListQuestions')
);
const CreateQuestion = lazy(
  () => import('@/pages/Assessments/Questions/CreateQuestion/CreateQuestion')
);
const ShowQuestion = lazy(
  () => import('@/pages/Assessments/Questions/ShowQuestion/ShowQuestion')
);
const EditQuestion = lazy(
  () => import('@/pages/Assessments/Questions/EditQuestion/EditQuestion')
);
const Assessments = lazy(
  () =>
    import('@/pages/Assessments/Assessments/ListAssessments/ListAssessments')
);
const ShowAssessment = lazy(
  () => import('@/pages/Assessments/Assessments/ShowAssessment/ShowAssessment')
);
const CreateAssessment = lazy(
  () =>
    import('@/pages/Assessments/Assessments/CreateAssessment/CreateAssessment')
);
const EditAssessment = lazy(
  () => import('@/pages/Assessments/Assessments/EditAssessment/EditAssessment')
);

export function Router() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="/desafio/questoes/:id/editar" element={<EditQuestion />} />
        <Route path="/desafio/questoes/criar" element={<CreateQuestion />} />
        <Route path="/desafio/questoes/:id" element={<ShowQuestion />} />
        <Route path="/desafio/questoes" element={<Questions />} />
        <Route
          path="/desafio/desafios/:id/editar"
          element={<EditAssessment />}
        />
        <Route path="/desafio/desafios/criar" element={<CreateAssessment />} />
        <Route path="/desafio/desafios/:id" element={<ShowAssessment />} />
        <Route path="/desafio/desafios" element={<Assessments />} />
        <Route path="/desafio" element={<Navigate to="/desafio/desafios" />} />
        <Route path="/minicurso/exercicios/:id" element={<ShowExercise />} />
        <Route
          path="/minicurso/exercicios/:id/editar"
          element={<EditExercise />}
        />
        <Route
          path="/minicurso/exercicios/criar"
          element={<CreateExercise />}
        />
        <Route path="/minicurso/exercicios" element={<Exercises />} />
        {[
          '/minicurso/videos/criar',
          '/minicurso/videos/:id/editar',
          '/minicurso/videos',
        ].map((p) => (
          <Route path={p} element={<Lectures />} key={p} />
        ))}
        <Route path="/minicurso/secoes/:id/editar" element={<EditSection />} />
        <Route path="/minicurso/secoes/criar" element={<CreateSection />} />
        <Route path="/minicurso/secoes" element={<Sections />} />
        <Route
          path="/minicurso"
          element={<Navigate to="/minicurso/secoes" />}
        />
        <Route
          path="/processo-seletivo"
          element={<Navigate to="/processo-seletivo/turmas" />}
        />
        {[
          '/processo-seletivo/turmas/criar',
          '/processo-seletivo/turmas/:batchId/editar',
          '/processo-seletivo/turmas',
        ].map((p) => (
          <Route path={p} element={<Batches />} key={p} />
        ))}
        <Route
          path="/processo-seletivo/turmas/:batchId/candidatos/:candidateId/resetar-desafio"
          element={<AssessmentReset />}
        />
        <Route
          path="/processo-seletivo/resultado-desafios"
          element={<AssessmentsResult />}
        />
        <Route
          path="/processo-seletivo/candidatos/:candidateId/editar"
          element={<EditCandidate />}
        />
        <Route path="/processo-seletivo/candidatos" element={<Candidates />} />

        {/* Routes for user authentication */}
        <Route path="/login" element={<SignIn />} />

        {/* Routes for redirect into Header Navigation */}
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
    </Suspense>
  );
}
