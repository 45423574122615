import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { useFormContext } from 'react-hook-form';

import { ErrorDisplay } from '@/components/errors';
import { Label } from '../../Label';
import PlainClipboard from './PlainClipboard';

import 'react-quill/dist/quill.snow.css';

Quill.register('modules/clipboard', PlainClipboard, true);

export function QuillEditor(props: Props) {
  const { label, fieldName, required } = props;
  const {
    setValue,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useFormContext();

  return (
    <>
      <Label>{label}</Label>
      <ReactQuill
        {...register(fieldName, { required })}
        theme="snow"
        modules={toolbarOptions}
        readOnly={isSubmitting}
        preserveWhitespace
        style={{ width: '100%' }}
        onChange={(content) => setValue(fieldName, content)}
        onBlur={() => setValue(fieldName, watch(fieldName))}
        defaultValue={watch(fieldName)}
      />

      {errors[fieldName] && (
        <ErrorDisplay>{`O campo ${label} é obrigatório.`}</ErrorDisplay>
      )}
    </>
  );
}

type Props = {
  label: string;
  fieldName: string;
  required?: boolean;
};

const toolbarOptions = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],

    [{ header: [1, 2, 3, false] }],

    [{ color: [] }, { background: [] }],

    ['link', 'image'],

    ['clean'],
  ],
};
