import React from 'react';
import styled from 'styled-components';
import { NavLinkProps, NavLink, useLocation } from 'react-router-dom';

export function ActivatableNavLink(props: ActivatableNavLinkProps) {
  const { to, label } = props;
  const location = useLocation();
  const url = location.pathname;
  const isActive = url.startsWith(
    typeof to === 'string' ? to : to.pathname || ' '
  );

  return (
    <StyledLink active={isActive} {...props}>
      {label}
    </StyledLink>
  );
}

export const StyledLink = styled(NavLink)<ActivatableStyledNavLinkProps>`
  font-weight: bold;
  color: ${({ active, theme }) =>
    active
      ? theme?.global?.colors?.['accent-1']
      : theme?.global?.colors?.['dark-4']};

  &:hover {
    color: ${({ theme }) => theme?.global?.colors?.['accent-1']};
  }
`;

export type ActivatableNavLinkProps = Omit<NavLinkProps, 'children'> & {
  label: string;
};

type ActivatableStyledNavLinkProps = NavLinkProps & {
  active?: boolean;
};

export type LinkElement = {
  label: string;
  to: string;
};
