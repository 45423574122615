import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Select as GrommetSelect, Spinner } from 'grommet';
import { Resource } from '@/protocols';
import { capitalizeFirstLetter } from '@/helpers';

export function ResourceSelector<R extends Resource>(props: Props<R>) {
  const {
    name,
    resources,
    selectedResource,
    onChange,
    labelAccessor,
    disabled = false,
  } = props;

  const options: Option[] = useMemo(() => {
    if (!resources) return [];

    return resources.map((resource) => ({
      label: `${resource[labelAccessor]}`,
      value: resource.id,
    }));
  }, [resources]);

  const selectedOption: Option | undefined = useMemo(() => {
    if (!selectedResource) return undefined;

    return {
      label: `${selectedResource[labelAccessor]}`,
      value: selectedResource.id,
    };
  }, [selectedResource]);

  return (
    <Select
      placeholder={capitalizeFirstLetter(name)}
      value={selectedOption}
      labelKey="label"
      valueKey="value"
      disabled={disabled || !resources}
      options={options}
      onChange={({ value: option }) => {
        const incomingResource = resources?.find((r) => r.id === option.value);
        onChange(incomingResource);
      }}
      icon={disabled ? <Spinner /> : true}
    />
  );
}

type Props<R extends Resource> = {
  name: string;
  labelAccessor: keyof R;
  resources: R[] | undefined;
  selectedResource: R | undefined;
  onChange: (resource?: R) => void;
  disabled?: boolean;
};

type Option = {
  label: string;
  value: string | number;
};

export const Select = styled(GrommetSelect)`
  background-color: ${({ theme }) => theme?.global?.colors?.white};
  color: ${({ theme }) => theme?.global?.colors?.black};
  height: 45px;
  font-size: 20px;
`;
