import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorDisplay } from '@/components';
import styled from 'styled-components';
import { Label } from '../Label';

export function NumberInput(props: Props) {
  const { disabled = false, label, fieldName, required = false } = props;
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Label>{label}</Label>
      <StyledNumberInput
        disabled={disabled}
        type="number"
        min={1}
        {...register(fieldName, { required })}
        value={watch(fieldName)}
        placeholder={label}
      />
      {errors[fieldName] && (
        <ErrorDisplay>{`O campo ${label} é obrigatório.`}</ErrorDisplay>
      )}
    </>
  );
}

const StyledNumberInput = styled.input`
  background-color: ${({ theme }) => theme?.global?.colors?.white};
  color: ${({ theme }) => theme?.global?.colors?.black};
  font-family: ${({ theme }) => theme?.global?.font?.family};
  height: 45px;
  font-size: 20px;
  outline: none;
  padding: 11px;
  border-radius: 4px;
  border: none;
  font-weight: bold;

  &::placeholder {
    color: ${({ theme }) => theme?.global?.colors?.placeholder};
  }
}
`;

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
};
