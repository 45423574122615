import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Header as GrommetHeader, Nav, BoxExtendedProps, Image } from 'grommet';
import { UserMenu } from '@driven-education/driven-styles';

import { useCurrentUser } from '@/contexts';
import Logo from '@/assets/images/logo.svg';
import { ActivatableNavLink, LinkElement } from './ActivatableNavLink';

export function Header(props: BoxExtendedProps) {
  const { signOut } = useCurrentUser();
  const userMenuItems: { label: string; onClick: () => void }[] = [
    {
      label: 'Sair',
      onClick: useCallback(() => signOut(), [signOut]),
    },
  ];

  return (
    <StyledHeader {...props}>
      <Nav direction="row" align="center">
        <Image src={Logo} height={20} />
        {anchors.map((a) => (
          <ActivatableNavLink key={a.to} to={a.to} label={a.label} />
        ))}
      </Nav>
      <UserMenu userName="Admin User" items={userMenuItems} />
    </StyledHeader>
  );
}

const anchors: LinkElement[] = [
  {
    label: 'Processo Seletivo',
    to: '/processo-seletivo',
  },
  {
    label: 'Minicurso',
    to: '/minicurso',
  },
  {
    label: 'Desafio',
    to: '/desafio',
  },
];

const StyledHeader = styled(GrommetHeader)`
  background: ${({ theme }) => theme?.global?.colors?.['dark-2']};
  padding: ${({ theme }) => theme?.global?.edgeSize?.small};
`;
