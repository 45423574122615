import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { Test } from '@/protocols';
import { Descriptor } from './Descriptor';

export function TestDisplay({ testResult, index }: Props): ReactElement {
  const state = testResult?.error ? 'failed' : 'passed';
  return (
    <Container>
      <Descriptor state={state} className="capitalize">
        {`Caso de Teste ${index + 1}: ${
          state === 'passed' ? 'Passou!' : 'Erro!'
        }`}
      </Descriptor>
      <SubDescriptor>{`Mensagem: ${testResult.message}`}</SubDescriptor>
      <ErrorDisplay testResult={testResult} />
    </Container>
  );
}

function ErrorDisplay({ testResult }: { testResult: Test }) {
  const { error } = testResult;

  if (!error) return null;

  if (error.payload)
    return (
      <>
        <SubDescriptor>{`Esperava: ${error.payload.expected}`}</SubDescriptor>
        <SubDescriptor>{`Recebeu: ${error.payload.actual}`}</SubDescriptor>
      </>
    );

  return <SubDescriptor>{`${error.name}: ${error.message}`}</SubDescriptor>;
}

type Props = {
  testResult: Test;
  index: number;
};

const Container = styled.div`
  font-weight: bold;
  line-height: 15px;
  margin: 15px 0;
  color: '#FFF';
`;

const SubDescriptor = styled.p`
  font-weight: 400;
  margin-top: 2px;
  font-size: 0.9rem;
  color: '#FFF';
`;
