import React from 'react';
import { BaseTable, BaseTableProps } from './BaseTable';

export function DraggableTable<R extends object>(
  props: DraggableTableProps<R>
) {
  const { columns, data, onDragEnd } = props;

  return (
    <BaseTable
      columns={columns}
      data={data}
      onDragEnd={onDragEnd}
      isDraggable
    />
  );
}

type DraggableTableProps<R> = Omit<
  BaseTableProps<R>,
  'isDraggable' | 'onDragEnd'
> & {
  onDragEnd: BaseTableProps['onDragEnd'];
};
