import styled from 'styled-components';
import React from 'react';
import { DrivenSpinner } from './DrivenSpinner';

export function LoadingComponent() {
  return (
    <LoadingPageWrapper>
      <DrivenSpinner />
    </LoadingPageWrapper>
  );
}
const LoadingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;
