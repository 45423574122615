import React, { useEffect } from 'react';

import { useTrailResource } from '@/hooks';
import { Section } from '@/protocols';
import { ResourceSelector } from '@/components';

export function SectionsSelector(props: Props) {
  const { onSectionSelect, shouldClear = false, onClear } = props;

  const {
    isLoading: areSectionsLoading,
    list: sections,
    current: selectedSection,
    setCurrent: setCurrentSection,
  } = useTrailResource<Section>({
    identifier: 'sectionId',
    queryParams: {
      name: 'getManySections',
      path: '/sections',
    },
  });

  useEffect(() => {
    onSectionSelect(selectedSection);
  }, [selectedSection]);

  useEffect(() => {
    if (!shouldClear) return;
    setCurrentSection(undefined);
    onClear?.();
  }, [shouldClear]);

  return (
    <ResourceSelector<Section>
      name="seção"
      resources={sections}
      labelAccessor="name"
      selectedResource={selectedSection}
      onChange={setCurrentSection}
      disabled={areSectionsLoading}
    />
  );
}

type Props = {
  onSectionSelect: (section?: Section) => void;
  shouldClear?: boolean;
  onClear?: () => void;
};
