import { createGlobalStyle } from 'styled-components';
import { Theme } from '@driven-education/driven-styles';

const GlobalStyle = createGlobalStyle<{ theme: Theme.ThemeType }>`
  ${Theme.defaultStyle} 
  strong { font-weight: bold !important; }
  
  em { font-style: italic !important}

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }
  
  .ql-toolbar .ql-stroke {
    fill: none !important;
    stroke: #fff !important;
  }

  .ql-toolbar .ql-fill {
    fill: #fff !important;
  }

  .ql-toolbar .ql-picker {
    color: #fff !important;
  }

  .ql-editor{
    background: rgba(255, 255, 255, 0.04) !important;
    min-height: 280px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }

  .ql-toolbar, .ql-container {
    border: none !important;
  }

  span.ql-picker-item {
    color: black
  }
`;

export default GlobalStyle;
