import React, { PropsWithChildren, ReactNode } from 'react';
import { Grid } from 'grommet';

import { Header } from './Header';
import { Sidebar } from './Sidebar';
import StyledCard from './StyledCard';

export function MainGrid({ children }: PropsWithChildren<ReactNode>) {
  const isGridAvailable = Grid.available;

  if (!isGridAvailable) {
    return (
      <div>Não disponível no seu browser. Por favor atualize ou use outro</div>
    );
  }

  return (
    <Grid
      fill
      rows={['auto', 'flex']}
      columns={['auto', 'flex']}
      height={{ min: '100vh' }}
      areas={[
        { name: 'header', start: [0, 0], end: [1, 0] },
        { name: 'sidebar', start: [0, 1], end: [0, 1] },
        { name: 'main', start: [1, 1], end: [1, 1] },
      ]}
    >
      <Header gridArea="header" />
      <Sidebar gridArea="sidebar" />
      <StyledCard level={3} gridArea="main" pad="medium" gap="medium">
        {children}
      </StyledCard>
    </Grid>
  );
}
