import styled, { css } from 'styled-components';

interface ErrorProps {
  addMinHeight?: boolean;
}

export const ErrorDisplay = styled.div<ErrorProps>`
  text-align: center;
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  ${({ theme }) =>
    css`
      color: ${theme.global?.colors?.['status-error']};
    `}

  ${({ addMinHeight }) => {
    if (addMinHeight) {
      return css`
        min-height: 16px;
      `;
    }
    return null;
  }}
`;
