import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { TestsResult } from '@/protocols';
import { TestDisplay } from './TestDisplay';

export function TestsMessage({
  testsResult: { passed, failed, results },
}: Props): ReactElement {
  const numberOfPassedTests = passed || 0;
  const numberOfFailedTests = failed || 0;

  return (
    <Container>
      <CardTest flex>
        <Descriptor>Resultado:</Descriptor>
        {numberOfPassedTests !== 0 && (
          <Descriptor state="passed">{`${numberOfPassedTests} Teste${
            numberOfPassedTests === 1 ? '' : 's'
          } Passando`}</Descriptor>
        )}
        {numberOfFailedTests !== 0 && numberOfPassedTests !== 0 && (
          <span>|</span>
        )}
        {numberOfFailedTests !== 0 && (
          <Descriptor state="failed">{`${numberOfFailedTests} Teste${
            numberOfFailedTests === 1 ? '' : 's'
          } Falhando`}</Descriptor>
        )}
      </CardTest>

      {results?.map((test, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <TestDisplay testResult={test} index={i} key={i} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.87);
  padding-left: 15px;
`;

const CardTest = styled.div<{ flex?: boolean }>`
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 15px;
  ${({ flex }) =>
    css`
      display: ${flex && 'flex'};
      gap: ${flex && '5px'};
    `}
`;

const Descriptor = styled.span<{ state?: 'passed' | 'failed' | undefined }>`
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 5px;
  font-size: 20px;
  ${({ state }) =>
    css`
      color: ${state === 'passed'
        ? '#55A366'
        : state === 'failed'
        ? '#A35555'
        : '#fff'};
    `}
`;

type Props = {
  testsResult: TestsResult;
};
