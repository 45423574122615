import React from 'react';
import { Button } from '@driven-education/driven-styles';

export function CreateResourceAction({
  onCreateClick,
}: CreateResourceActionProps) {
  return <Button type="button" onClick={onCreateClick} primary label="Criar" />;
}

export type CreateResourceActionProps = {
  onCreateClick?: () => void;
};
