import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { TableCell } from 'grommet';
import { HiOutlineViewGrid } from 'react-icons/hi';

export function DragButton(
  props: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
) {
  return (
    <TableCell>
      <span {...props}>
        <HiOutlineViewGrid size={20} />
      </span>
    </TableCell>
  );
}
