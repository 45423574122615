import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  ReactElement,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '@/hooks';
import { ApiClient } from '@/api';
import { User } from '@/protocols';
import { useErrorHandler } from './ErrorHandlerContext';

interface IProvider {
  user: User | null;
  token: string;
  authenticatedApi: ApiClient | null;
  signOut: () => void;
  signIn: (key: string) => void;
  redirectToHome: () => void;
}

export const UserContext = createContext<IProvider>({} as IProvider);

export function UserProvider({
  children,
}: PropsWithChildren<ReactNode>): ReactElement {
  const [token, setToken] = useLocalStorage<string>('selection-admin-auth', '');
  const [user, setUser] = useState<User | null>(null);
  const { onLogout } = useErrorHandler();
  const navigate = useNavigate();

  const authenticatedApi = useMemo((): ApiClient | null => {
    if (!token) {
      redirectToLogin();
      return null;
    }

    const api = new ApiClient();
    api.authenticate(token);
    return api;
  }, [token]);

  const result: IProvider = useMemo(
    (): IProvider => ({
      user,
      token,
      authenticatedApi,
      signOut,
      signIn,
      redirectToHome,
    }),
    [user, token, authenticatedApi]
  );

  useEffect(() => {
    if (!token) {
      setUser(null);
      redirectToLogin();
    }
  }, [token, authenticatedApi]);

  function signOut() {
    onLogout();

    authenticatedApi
      ?.delete('/authentication/logout')
      .catch(() => {
        // do nothing
      })
      .finally(() => {
        setToken('');
        redirectToLogin();
      });
  }

  function redirectToHome() {
    navigate('/');
  }

  function redirectToLogin() {
    navigate('/login');
  }

  function signIn(key: string) {
    setToken(key);
  }

  return <UserContext.Provider value={result}>{children}</UserContext.Provider>;
}

export const useCurrentUser = (): IProvider => useContext(UserContext);
