import React, { useState } from 'react';
import { Card, Heading } from '@driven-education/driven-styles';
import { Tabs } from 'grommet';
import styled from 'styled-components';

import { Editor, Tab } from '@/components';

export function ShowTabs({ data }: ShowProps) {
  const { summary, statement, tests } = data;
  const [index, setIndex] = useState(0);

  const onActive = (nextIndex: number) => setIndex(nextIndex);
  return (
    <Card>
      <Tabs
        activeIndex={index}
        onActive={onActive}
        alignControls="start"
        flex="grow"
        style={{ width: '100%' }}
      >
        <Tab title="RESUMO" isActive={index === 0}>
          <Card>
            {summary.map(({ label, value }) => (
              <StyledCard>
                <Heading level={4}>{label}</Heading>
                <p>{value}</p>
              </StyledCard>
            ))}
          </Card>
        </Tab>
        <Tab title="ENUNCIADO" isActive={index === 1}>
          <Card>
            <StyledCard>
              <Heading level={4}>Enunciado</Heading>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: statement.statement,
                }}
              />
            </StyledCard>
            <StyledCard>
              <Heading level={4}>Código Inicial</Heading>
              <Editor value={statement.initialCode || ''} readOnly />
            </StyledCard>
          </Card>
        </Tab>
        <Tab title="TESTES AUTOMATIZADOS" isActive={index === 2}>
          <Card>
            <StyledCard>
              <Heading level={4}>Solução</Heading>
              <Editor value={tests.solution} readOnly />
            </StyledCard>
            <StyledCard>
              <Heading level={4}>Testes</Heading>
              {tests.testCases.map((t) => (
                <StyledCard>
                  <Editor value={t} readOnly />
                </StyledCard>
              ))}
            </StyledCard>
          </Card>
        </Tab>
      </Tabs>
    </Card>
  );
}

export type ShowProps = {
  data: {
    summary: {
      label: string;
      value: string | number | JSX.Element;
    }[];
    statement: {
      statement: string;
      initialCode: string;
    };
    tests: {
      solution: string;
      testCases: string[];
    };
  };
};

const StyledCard = styled(Card)`
  padding: 10px 0px;

  ${Heading} {
    padding: 0 0 5px 0;
  }
`;
