import { useToast } from '@driven-education/driven-styles';
import { useEffect, useState } from 'react';
import { useCurrentUser } from '@/contexts';

export function useReorder<R extends Item>(apiPath: string, data: R[]) {
  const [sortedData, setSortedData] = useState(data);
  const { authenticatedApi } = useCurrentUser();
  const { toast } = useToast();

  function sortData(): R[] {
    return [...data].sort((a, b) => a.order - b.order);
  }

  useEffect(() => {
    setSortedData(sortData());
  }, [data]);

  async function reorder({ from, to }: ReorderParams) {
    if (!authenticatedApi || to === undefined) return;
    if (from === to) return;

    const newList = [...sortedData];
    const [removed] = newList.splice(from, 1);
    newList.splice(to, 0, removed);

    try {
      await authenticatedApi.put(`${apiPath}/reorder`, {
        body: {
          items: newList.map((r, i) => ({
            id: r.id,
            order: i + 1,
          })),
        },
      });

      setSortedData(
        newList.map((e, i) => ({
          ...e,
          order: i + 1,
        }))
      );
      toast({
        title: 'Ordenação atualizada',
        description: 'Os itens foram reordenados',
        status: 'success',
      });
    } catch (e) {
      toast({
        title: 'Erro ao atualizar ordenação',
        description: 'Os itens não foram reordenados',
        status: 'error',
      });
    }
  }

  return {
    sortedData,
    reorder,
  };
}

export type Item = {
  id: number;
  order: number;
  [key: string]: unknown;
};

type ReorderParams = {
  from: number;
  to?: number;
};
