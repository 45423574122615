import React, { ReactElement } from 'react';
import {
  ConfirmationDialog as Dialog,
  ConfirmationDialogProps,
  Heading,
  Button,
} from '@driven-education/driven-styles';
import styled from 'styled-components';
import { Layer } from 'grommet';

export function ConfirmationDialog(
  props: ConfirmationDialogProps
): ReactElement {
  const { children } = props;

  return (
    <Layer>
      <StyledConfirmationDialog {...props}>{children}</StyledConfirmationDialog>
    </Layer>
  );
}

const StyledConfirmationDialog = styled(Dialog)`
  text-align: justify;
  font-size: 1.1rem;
  div {
    min-height: fit-content !important;
  }
  ${Heading} {
    padding: 16px 0 0 0;
  }
  ${Button} {
    margin: 10px 0 0 10px;
  }
`;
