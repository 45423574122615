import { CheckBox } from 'grommet';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorDisplay } from '@/components/errors';
import { Label } from '../Label';

export function BooleanInput(props: Props) {
  const { disabled = false, label, fieldName, required = false } = props;
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Label>{label}</Label>
      <CheckBox
        checked={watch(fieldName)}
        disabled={disabled}
        toggle
        label={watch(fieldName) ? 'Sim' : 'Não'}
        {...register(fieldName, { required })}
      />

      {errors[fieldName] && (
        <ErrorDisplay>{`O campo ${label} é obrigatório.`}</ErrorDisplay>
      )}
    </>
  );
}

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
};
