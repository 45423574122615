import { Table } from 'grommet';
import styled, { css } from 'styled-components';

export const StyledTable = styled(Table)`
  cursor: pointer;
  border-collapse: collapse;
  border-radius: 20px;
  overflow: hidden;

  ${({ theme }) =>
    css`
      thead {
        color: ${theme.global?.colors?.text};
        font-weight: bold;
        text-transform: capitalize;
      }

      color: ${theme.global?.colors?.['text-1']};
      background-color: ${theme.global?.colors?.['dark-1']};
      tr:hover {
        background-color: ${theme.global?.colors?.['dark-2']};
        cursor: pointer;
      }
    `}

  td,
  th {
    border-left: none;
    border-right: none;

    padding: 12px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;
