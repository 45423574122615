import styled, { keyframes } from 'styled-components';
import React from 'react';

export function DrivenSpinner() {
  return (
    <SpinnerWrapper>
      <Circle />
    </SpinnerWrapper>
  );
}

const spinAnimation = keyframes`
from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
`;

const lettersAnimation = keyframes`
  0% {content: "C|";}
  25% {content: "Ca|";}
  30% {content: "Car|";}
  40% {content: "Carr|";}
  50% {content: "Carre|";}
  60% {content: "Carreg|";}
  70% {content: "Carregan|";}
  80% {content: "Carregand|";}
  90% {content: "Carregando|";}
  100% {content: "Carregando...|";}
  
`;

const Circle = styled.div`
  height: 100px;
  width: 100px;
  border-style: solid;
  border-right-color: ${({ theme }) => theme.global?.colors?.brand}!important;
  border-width: 5px;
  border-radius: 50%;
  border-color: white;
  animation-name: ${spinAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  margin-bottom: 10px !important;
`;

const SpinnerWrapper = styled.span`
  text-align: center;
  margin: auto auto;
  &:after {
    content: 'Original Text';
    font-size: 16px;
    animation-name: head;
    animation-name: ${lettersAnimation};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: ${({ theme }) => theme.global?.colors?.brand}!important;
  }
`;
