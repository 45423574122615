import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Input } from '@driven-education/driven-styles';

import { ErrorDisplay } from '@/components/errors';
import { Label } from '../Label';

export function DateInput(props: Props) {
  const { disabled = false, label, fieldName, required = false } = props;
  const {
    register,
    watch,
    formState: { errors, isSubmitting },
  } = useFormContext();

  return (
    <>
      <Label>{label}</Label>
      <Input
        {...register(fieldName, { required })}
        type="date"
        value={watch(fieldName)}
        disabled={isSubmitting || disabled}
        placeholder={label}
      />

      {errors[fieldName] && (
        <ErrorDisplay>{`O campo ${label} é obrigatório.`}</ErrorDisplay>
      )}
    </>
  );
}

type Props = {
  label: string;
  fieldName: string;
  disabled?: boolean;
  required?: boolean;
};
