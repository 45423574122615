import { Modal, Heading, Button } from '@driven-education/driven-styles';
import React from 'react';
import styled from 'styled-components';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UnpackNestedValue,
  DeepPartial,
} from 'react-hook-form';

export function FormModal<R>(props: Props<R>) {
  const { onSubmit, title, onClose, defaultValues, children } = props;
  const formArgs = defaultValues ? { defaultValues } : undefined;
  const formState = useForm<R>(formArgs);
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = formState;

  return (
    <FormProvider {...formState}>
      <Container
        onClose={() => {
          if (isSubmitting) return;

          onClose();
          reset();
        }}
      >
        <Heading level={3}>{title}</Heading>
        <Form onSubmit={handleSubmit<R>(onSubmit)}>
          {children}
          <Button
            type="submit"
            className="btn-submit"
            label={isSubmitting ? 'Salvando...' : 'Salvar'}
            disabled={isSubmitting}
            primary
          />
        </Form>
      </Container>
    </FormProvider>
  );
}

type Props<R> = {
  onSubmit: SubmitHandler<R>;
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  defaultValues?: UnpackNestedValue<DeepPartial<R>>;
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  width: 100%;
  margin-top: 25px;

  .btn-submit {
    align-self: center;
  }
`;

const Container = styled(Modal)`
  width: 75%;
  padding: 15px 30px;
  overflow-y: auto;
`;
