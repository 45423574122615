import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCurrentUser } from '@/contexts';
import { Resource } from '@/protocols';

export function useTrailResource<R extends Resource>({
  identifier,
  dependency,
  queryParams,
}: Args): Result<R> {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const { authenticatedApi } = useCurrentUser();

  const resourceId = searchParams.get(identifier);
  const dependenciesSearchParam = dependency
    ? searchParams.get(dependency)
    : true;
  const [selectedResource, setSelectedResourceState] = useState<
    R | undefined
  >();

  const { data: resources, isLoading } = useQuery(
    [queryParams.name, dependenciesSearchParam],
    () =>
      dependenciesSearchParam && authenticatedApi
        ? authenticatedApi
            .get<R[]>(
              queryParams.path,
              dependency
                ? {
                    query: {
                      filter: {
                        [dependency]: +dependenciesSearchParam,
                      },
                    },
                  }
                : {}
            )
            .then((res) => res.body)
        : [],
    {
      enabled: !!authenticatedApi && !!dependenciesSearchParam,
    }
  );

  useEffect(() => {
    if (isLoading) return;
    const resource = resources?.find((r) => r.id.toString() === resourceId);
    if (!resource) {
      searchParams.delete(identifier);
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }

    setSelectedResourceState(resource);
  }, [resources, isLoading]);

  useEffect(() => {
    if (!selectedResource || selectedResource.id.toString() === resourceId)
      return;

    searchParams.set(identifier, selectedResource.id.toString());
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }, [selectedResource]);

  useEffect(() => {
    if (selectedResource) {
      setSelectedResourceState(undefined);
    }
  }, [dependenciesSearchParam]);

  return {
    isLoading,
    list: resources,
    current: selectedResource,
    setCurrent: setSelectedResourceState,
  };
}

type Args = {
  identifier: string;
  queryParams: {
    name: string;
    path: string;
  };
  dependency?: string;
};

type Result<R> = {
  isLoading: boolean;
  list?: R[];
  current?: R;
  setCurrent: (resource?: R) => void;
};
