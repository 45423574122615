import React, { useEffect } from 'react';
import { useTrailResource } from '@/hooks';
import { Assessment } from '@/protocols';
import { ResourceSelector } from '@/components';

export function AssessmentsSelector(props: Props) {
  const { onAssessmentSelect, shouldClear = false, onClear } = props;

  const {
    isLoading: areAssessmentsLoading,
    list: assessments,
    current: selectedAssessment,
    setCurrent: setCurrentAssessment,
  } = useTrailResource<Assessment>({
    identifier: 'assesmentId',
    queryParams: {
      name: 'getManyAssessments',
      path: '/assessments',
    },
  });

  useEffect(() => {
    onAssessmentSelect(selectedAssessment);
  }, [selectedAssessment]);

  useEffect(() => {
    if (!shouldClear) return;
    setCurrentAssessment(undefined);
    onClear?.();
  }, [shouldClear]);

  return (
    <ResourceSelector<Assessment>
      name="Versão do Desafio"
      resources={assessments}
      labelAccessor="name"
      selectedResource={selectedAssessment}
      onChange={setCurrentAssessment}
      disabled={areAssessmentsLoading}
    />
  );
}

type Props = {
  onAssessmentSelect: (assessment?: Assessment) => void;
  shouldClear?: boolean;
  onClear?: () => void;
};
