import React, { PropsWithChildren, ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider } from '@driven-education/driven-styles';

import { ErrorHandlerProvider, UserProvider } from '@/contexts';
import { QueryProvider } from './QueryProvider';

export function MainProvider({ children }: PropsWithChildren<ReactNode>) {
  return (
    <StylesProvider>
      <BrowserRouter>
        <ErrorHandlerProvider>
          <UserProvider>
            <QueryProvider>{children}</QueryProvider>
          </UserProvider>
        </ErrorHandlerProvider>
      </BrowserRouter>
    </StylesProvider>
  );
}
