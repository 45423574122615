import styled, { css } from 'styled-components';

export const Descriptor = styled.span<{
  state?: 'passed' | 'failed' | undefined;
}>`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 5px;
  ${({ state }) =>
    css`
      color: ${state === 'passed'
        ? '#55A366'
        : state === 'failed'
        ? '#A35555'
        : '#FFF'};
    `}
`;
