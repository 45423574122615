import { ApiClient } from '@/api';
import { useUploadImage } from '../hooks/useUploadImage';

export async function parseBase64ToUrlBucket(
  html: string,
  api: ApiClient
): Promise<string> {
  const container = document.createElement('div');
  container.innerHTML = html;

  const images = container.querySelectorAll('img');

  for (let i = 0; i < images.length; i += 1) {
    const image = images[i];
    const base64 = image.getAttribute('src');

    if (!base64 || isUrl(base64)) continue;

    const file = dataUrlToFile(base64, 'image.png');
    if (!file) continue;
    const url = await useUploadImage(file, api);

    image.setAttribute('src', url);
  }

  return container.innerHTML;
}

function dataUrlToFile(dataUrl: string, filename: string): File | undefined {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

const isUrl = (string: string) => {
  try {
    const url = new URL(string);
    return ['http:', 'https:'].includes(url.protocol);
  } catch (e) {
    return false;
  }
};
