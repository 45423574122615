import getYouTubeID from 'get-youtube-id';
import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';

dayjs.extend(durationPlugin);

async function getDurationForVimeo(url: string): Promise<number | null> {
  const response = await fetch(
    `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(url)}`,
    {
      method: 'GET',
    }
  );
  const data: VimeoResponse = await response.json();
  return data.duration;
}

type VimeoResponse = {
  duration: number;
};

async function getDurationForYoutube(url: string): Promise<number | null> {
  const id = getYouTubeID(url);
  const ytKey = process.env.REACT_APP_YT_API_KEY;
  const response = await fetch(
    `https://www.googleapis.com/youtube/v3/videos?id=${id}&part=contentDetails&key=${ytKey}`,
    {
      method: 'GET',
    }
  );
  const {
    items: [video],
  }: YoutubeResponse = await response.json();

  return dayjs.duration(video.contentDetails.duration).asSeconds();
}

type YoutubeResponse = {
  kind: string;
  etag: string;
  items: Array<{
    kind: string;
    etag: string;
    id: string;
    contentDetails: {
      duration: string;
      dimension: string;
      definition: string;
      caption: string;
      licensedContent: true;
      contentRating: Record<string, string>;
      projection: string;
    };
  }>;
  pageInfo: {
    totalResults: number;
    resultsPerPage: number;
  };
};

export async function getVideoDuration(url: string) {
  let duration = 1;

  if (url.includes('vimeo')) {
    const result = await getDurationForVimeo(url);
    duration = result || duration;
  }

  if (url.includes('youtube')) {
    const result = await getDurationForYoutube(url);
    duration = result || duration;
  }

  return duration;
}
